<template>
  <div class="product"
       style="padding-top:0">
    <div class="swiper-box">
      <swiper :options="swiperOption"
              ref="mySwiper"
              class="swiper-wrapper"
              @slide-change-transition-end="handleClickSlide">
        <swiper-slide v-for="(item, index) in productTop.items"
                      :key="index"
                      class="swioer-item"
                      swiper-animate-effect="shutter2">
          <div class="swiper-box"
               style="position:relative">
            <div @click="todetails(item)"
                 class="wwq1"
                 style="">
              <img :src="btnsrc"
                   alt=""
                   style="width:100%;height:100%">
            </div>
            <img :src="item.img"
                 alt="" />
            <!--            <img :src="item.imgs[langtype]"-->
            <!--                 alt="" />-->
            <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
          </div>
        </swiper-slide>
        <div class="swiper-pagination"
             slot="pagination"></div>
      </swiper>
      <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
    </div>
    <div class="inmain clearfix">
      <div class="product clearfix">
        <div style="height:1px"></div>
        <div class="second-nav clearfix wow fadeInUp"
             data-wow-duration="1.5s"
             data-wow-delay="0.1s">
          <div class="container">
            <div>
              <span id="yi1"
                    @click="ProductCenter">{{$t('product.cpzxsy')}}</span>
              <!-- 产品中心首页 -->
            </div>
            <ul>
              <li id="er5"
                  v-for="(item,index) in classifyImg.items"
                  :key="index"
                  @click="platformTitle(item)">
                <div class="box"
                     v-if="item.online==1">
                  <div class="pic">
                    <el-image class="middleCenter h"
                              :src="item.img"
                              alt=""></el-image>
                    <el-image class="middleCenter hh"
                              :src="item.img"
                              alt=""></el-image>
                  </div>
                  <div class="bt"
                       style="text-align:center;white-space: nowrap;">{{item.names?item.names[langtype]||item.name:item.name}}</div>
                </div>
              </li>
            </ul>
            <div style="height:84px;display: flex;align-items: center;justify-content: flex-end;position: relative;"
                 class="inouts">
              <input type="text"
                     style="padding:8px 15px;border:1px solid #7f0114; width: 225px; border-radius: 3px;box-sizing: border-box;border-right: 0;border-top-right-radius: 0;border-bottom-right-radius: 0;"
                     class="search_bar"><button style="padding:9px 15px; background-color:#7f0114; color: #fff; border-left: 0;border: 1px solid #7f0114;border-top-right-radius: 10px;border-bottom-right-radius: 10px;cursor: pointer;">{{ $t('product.ss') }}</button>
              <div v-if="false"
                   style="position:absolute;width:100%;height:300px; overflow-y: scroll; z-index: 999; background-color: #eee;top:60px;width: 225px;right:58px"></div>
            </div>
          </div>
        </div>
        <main id="main1">
          <router-view />
        </main>
      </div>
    </div>

  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: [],
  components: { swiper, swiperSlide },
  data () {
    return {
      productTop: {},
      classifyImg: {},
      langtype: null,
      products: [],
      loading: false,
      btnsrc: null,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  name: "product",
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    // this.langtype=localStorage.getItem('lang')
    console.log(this.langtype)
    let _this = this
    this.getProductList()
    let sharing = localStorage.getItem("sharing")
    setTimeout(() => {
      console.log(this.classifyImg);

    }, 2000)
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", function (e) {
      //e.key : 是值发生变化的key
      if (e.key === "sharing") {
        _this.ProductCenter()
        setTimeout(item => {
          _this.classifyImg.items.forEach((item) => {
            if (item.name == e.newValue) {
              if (item) {
                _this.platformTitle(item)
              }
            }
          })
        }, 500)
      }
    })
  },

  mounted () {
    this.$nextTick(() => {
      console.log('执行')
      // 在dom渲染完后,再执行动画
      this.$wow.init();
      var lang = localStorage.getItem('lang')
      this.btnsrc = require('../assets/product/once_' + lang + '.png')
    });
  },
  methods: {
    todetails (e) {
      if (e.expansion) {
        document.location.href = e.expansion
      }
    },
    handleClickSlide () {
      // console.log(222);
    },
    async getProductList () {
      console.log('加载轮播图')
      let productTop = await this.api.get(this.apiPath.productTop, {});
      console.log(productTop)
      this.productTop = productTop
      let classifyImg = await this.api.get(this.apiPath.classifyImg, {});
      // console.log(classifyImg);
      classifyImg.items.sort((a, b) => a.index - b.index)
      console.log(classifyImg.items)
      for (var item of classifyImg.items) {
        item.names = JSON.parse(item.names)
      }
      // let classifyImgh = await this.api.get(this.apiPath.classifyImgh,{});
      // 合并title图片
      // classifyImg.items.forEach((i,index) => {
      //   i.imgh = classifyImgh.items[index].img
      // });
      this.classifyImg = classifyImg
      this.loading = true
      let allProducts = await this.api.get(this.apiPath.allProducts, {});
      console.log(allProducts)
      this.products = allProducts
      this.loading = false
      for (var item of this.productTop.items) {
        item.img = JSON.parse(item.imgs) ? JSON.parse(item.imgs)[this.langtype] || item.img : item.img
      }
    },
    async platformTitle (i) {
      console.log(i)
      switch (i.id) {
        case 23:
          console.log('切换23')
          let productTop23 = await this.api.get(this.apiPath.productTop1, {});
          console.log(productTop23)
          this.productTop = productTop23
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/safetyHat'
          })
          break;
        case 21:
          console.log('切换21')
          let productTop21 = await this.api.get(this.apiPath.productTop2, {});
          this.productTop = productTop21
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/smokeDetector'
          })
          break;
        case 19:
          console.log('切换19')
          let productTop19 = await this.api.get(this.apiPath.productTop3, {});
          this.productTop = productTop19
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/watch'
          })
          break;
        case 10:
          console.log('切换10')
          let productTop10 = await this.api.get(this.apiPath.productTop4, {});
          this.productTop = productTop10
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/smartElectricity'
          })
          break;
        case 41:
          console.log('切换41')
          let productTop41 = await this.api.get(this.apiPath.productTop5, {});
          this.productTop = productTop41
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/waterMeter'
          })
          break;
        case 42:
          console.log('切换42')
          let productTop42 = await this.api.get(this.apiPath.productTop6, {});
          this.productTop = productTop42
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/platForm'
          })
          break;
        case 45:
          console.log('切换45')
          let productTop45 = await this.api.get(this.apiPath.productTop7, {});
          this.productTop = productTop45
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/touringCar'
          })
          break;
        case 48:
          console.log('切换48')
          let productTop48 = await this.api.get(this.apiPath.productTop8, {});
          console.log(this.productTop48)
          this.productTop = productTop48
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/fireEngine'
          })
          break;
        case 54:
          console.log('切换54')
          let productTop49 = await this.api.get(this.apiPath.productTop9, {});
          this.productTop = productTop49
          this.$router.push({
            path: this.$router.history.current.matched[0].path + '/product/monitor'
          })
          break;
        default:
          let twoFireProducts = await this.api.get(this.apiPath.twoFireProducts + '?id=' + `${i.id}`, {});
          console.log(twoFireProducts)
          //是否有二级分类
          if (twoFireProducts.items.length) {
            // this.classifyImg = twoFireProducts
            let fireProducts = await this.api.get(this.apiPath.fireProducts + twoFireProducts.items[0].id + '/detailSPU', {});
            if (fireProducts.items.length) {
              console.log(fireProducts.items)
              fireProducts.items.sort((a, b) => a.sort2 - b.sort2)
              this.products = fireProducts.items
            } else {
              this.products = []
            }
          } else {
            // let fireProducts = await this.api.get(this.apiPath.fireProducts + i.id + '/detailSPU',{});
            // this.products = fireProducts.items
            // console.log(fireProducts);
          }
      }
      console.log(this.productTop)
      for (var item of this.productTop.items) {
        item.img = JSON.parse(item.imgs) ? JSON.parse(item.imgs)[this.langtype] || item.img : item.img
      }
    },
    async ProductCenter () {
      console.log('加载首页轮播图')
      let productTop = await this.api.get(this.apiPath.productTop, {});
      console.log(productTop)
      this.productTop = productTop
      console.log(this.productTop)
      for (var item of this.productTop.items) {
        item.img = JSON.parse(item.imgs) ? JSON.parse(item.imgs)[this.langtype] || item.img : item.img
      }
      this.$router.push({
        path: this.$router.history.current.matched[0].path + '/product/index'
      })
    },
    buy (item) {
      console.log(this.$router.history.current)
      //立即购买
      this.$router.push({
        path: this.$router.history.current.matched[0].path + "/goodsDetail?id=" + item.id,
      });
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
@import url("../views/products/common.css");
.goods-img {
  background: #fff;
  border-bottom: 6px solid #f7f7f7;
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.wwq1 {
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  left: 61%;
  transform: translate(-50%, 0);
  bottom: 6%;
  width: 9%;
  height: 8%;
}
.goods-box {
  text-align: center;
  .no_goods {
    width: 600px;
    height: 400px;
  }
}

.product {
  .inbanner {
    margin-top: -35px;
  }
  #er5 {
    cursor: pointer;
    .pic {
      background: #fff;
    }
  }
}
.second-nav {
  padding: 0 130px;
}
.containers {
  padding: 0 130px;
}
.inmain {
  #yi1 {
    width: 200px;
    cursor: pointer;
    display: block;
    background: url("../assets/product/title.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: initial;
    background-position: center;
  }
}
.productlist {
  cursor: pointer;
  .price {
    color: red;
  }
}
@media screen and (max-width: 750px) {
  .top {
    .title {
      margin-left: 3%;
    }
  }
  .second-nav {
    padding: 0;
    width: 100%;
    overflow-x: auto;
    padding: 0 10px;
    ul {
      display: flex;
      .box {
        width: 60px;
      }
    }
    #yi1 {
      display: none;
    }
  }
  .inmain {
    .containers {
      padding: 0;
    }
    .productlist li {
      width: 49%;
      padding: 0 10px;
      .pic {
        height: 120px;
      }
    }
  }
  .inouts {
    display: none !important;
  }
  .second-nav {
    margin-bottom: 10px !important;
  }
}
.swiper-box {
  position: relative;
  .btn-box {
    font-size: 20px;
    margin-top: 10px;
    span {
      color: rgb(95, 139, 200);
    }
  }
  .center0 {
    width: 100%;
    position: absolute;
    top: 240px;
    left: 30px;
    text-align: center;
    .name {
      font-size: 60px;
      color: #fff;
    }
    .title {
      font-size: 25px;
      color: #fff;
    }
    .description {
      font-size: 10px;
      color: #fff;
    }
  }
  .center1 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 80px;
    text-align: center;
    color: #000;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center2 {
    width: 40%;
    position: absolute;
    top: 35%;
    right: 190px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;

    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
  .center3 {
    width: 40%;
    position: absolute;
    top: 35%;
    left: 30px;
    text-align: center;
    color: #fff;
    .name {
      font-size: 22px;
    }
    .title {
      font-size: 50px;
    }
    .description {
      font-size: 30px;
    }
  }
}
</style>
